<template>
  <a-card>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="3" :xs="6">
            <a-button type="primary" @click="loadData">查询</a-button>
          </a-col>
          <a-col :md="3" :xs="6" v-if="true">
            <a-button type="primary" @click="exportData">导出</a-button>
          </a-col>
          <a-col :md="4" :xs="10">
            <a-checkbox :disabled="!(this.dataSource && this.dataSource.length >0)" @change="onSpreadChange">全部展开</a-checkbox>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      ref="table"
      size="small"
      :rowKey="(item) => { return item.corporation + item.corporation_id }"
      :loading="loading"
      :columns="columns"
      :pagination="false"
      :data-source="dataSource"
      :expandRowByClick="true"
      :expandIconColumnIndex="-1"
      :expandedRowKeys="expRowKeys"
      @expandedRowsChange="expandedRowsChange"
      @customHeaderRow="customHeaderRow">
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <a-table
        slot="expandedRowRender"
        slot-scope="record"
        ref="child_table"
        size="small"
        rowKey="index"
        :columns="children_columns"
        :data-source="record.children_data"
        :pagination="false"
        :scroll="{ x: 1000 }"
      >
        <div slot="money_renders" slot-scope="texts" style="text-align: right; min-width: 100px; max-width: 140px">
          {{ (texts / 100).toFixed(2) | numberFormat }}
        </div>
      </a-table>
    </a-table>
  </a-card>
</template>

<script>
import { Dictionaries } from '@/common/AllConstant'
import { property_arrears, property_arrears_export } from '@/api/property_bill'
export default {
  name: 'TableList',
  components: {
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 查询参数
      queryParam: {},
      expRowKeys: [],
      // 表头
      columns: [
        {
          title: '企业',
          dataIndex: 'corporation',
          width: 180,
          ellipsis: true
        },
        {
          title: '总额',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' },
          width: 140,
          align: 'right'
        },
        {
          title: '电费',
          dataIndex: 'electricity_s',
          width: 180,
          scopedSlots: { customRender: 'money_render' },
          align: 'right'
        },
        {
          title: '物业费',
          dataIndex: 'property_s',
          width: 180,
          scopedSlots: { customRender: 'money_render' },
          align: 'right'
        },
        {
          title: '租金',
          dataIndex: 'rental_s',
          width: 180,
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '水费',
          dataIndex: 'water_s',
          width: 180,
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        }
      ],
      children_columns: [
        {
          title: '时间',
          dataIndex: 'start_date',
          customRender: (text, record) => record.start_date === undefined ||
          record.end_date === undefined ? record.create_date
            : record.start_date + '至' + record.end_date
        },
        {
          title: '批次',
          dataIndex: 'batch',
          customRender: (text) => text === undefined || text === '' ? '-' : text
        },
        {
          title: '金额',
          dataIndex: 'total_payment',
          scopedSlots: { customRender: 'money_renders' },
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          width: 100
        },
        {
          title: '所属人',
          dataIndex: 'property_owner',
          customRender: (text) => this.$Dictionaries.property_owner[text],
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ]
    }
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    loadData () {
      if (this.queryParam.start_date === undefined) {
        this.$message.warning('选择时间')
        return
      }
      property_arrears(this.queryParam)
        .then(res => {
          console.log(res.data, '我是数据列表@')
          this.dataSource = res.data
          let index = 1
          if (this.dataSource) {
            this.dataSource = this.dataSource.map(item => {
              item.children_data = []
              item.electricity_s = item.electricity.s
              item.property_s = item.propertys.s
              item.rental_s = item.rental.s
              item.water_s = item.water.s
              item.children_data.push(...item.electricity.record.map(child => {
                child.type = '电费'
                child.index = index++
                return child
              }))
              item.electricity.record = undefined
              item.children_data.push(...item.propertys.record.map(child => {
                child.type = '物业费'
                child.index = index++
                return child
              }))
              item.propertys.record = undefined
              item.children_data.push(...item.rental.record.map(child => {
                child.type = '租金'
                child.index = index++
                return child
              }))
              item.rental.record = undefined
              item.children_data.push(...item.water.record.map(child => {
                child.type = '水费'
                child.index = index++
                return child
              }))
              item.water.record = undefined
              return item
            })
          }
        })
    },
    exportData () {
      if (this.queryParam.start_date === undefined) {
        this.$message.warning('选择时间')
        return
      }
      property_arrears_export(this.queryParam).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `企业欠款.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    expandedRowsChange (val) {
      this.expRowKeys = val
      console.log('expandedRowsChange', val)
    },
    customHeaderRow (conlumn, index) {
      console.log('customHeaderRow', conlumn, index)
    },
    expandAllRows (val) {
      console.log('expandAllRows', val)
      if (val) {
        const expandRows_ = []
        // 全部展开
        for (const i in this.dataSource) {
          const item = this.dataSource[i]
          expandRows_.push(item.corporation + item.corporation_id)
        }
        this.expandedRowsChange(expandRows_)
      } else {
        // 全部收回
        this.expandedRowsChange([])
      }
    },
    onSpreadChange (e) {
      if (this.dataSource && this.dataSource.length > 0) {
        // this.expandAllRows(this.expRowKeys.length === 0)
        this.expandAllRows(e.target.checked)
        // event.target.innerHTML = this.expRowKeys.length === 0 ? '展开' : '关闭'
      }
    }
  }
}
</script>
